<template>
  <div class="content contract">
    <Title currentPageTitle="通知中心"></Title>
    <div class="search-area">
      <el-row :gutter="24">
        <el-col :span="8"
          ><div class="search-area-div">处理状态：</div>
          <el-select size="mini" v-model="page.isFinish" placeholder="请选择" class="ipt-sty" clearable>
            <el-option
              v-for="item in tabIndex == 0 ? isFinishDealOption : isFinishMsgOption"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="8"
          ><div class="search-area-div">通知内容：</div>
          <el-input size="mini" v-model="page.content" class="ipt-sty" placeholder="请输入"></el-input
        ></el-col>
        <el-col :span="8">
          <el-button size="mini" type="info" @click="getList()">搜索</el-button>
          <el-button size="mini" type="danger" @click="emptySearch">清空</el-button>
        </el-col>
      </el-row>
    </div>
    <div class="back-color-fff  content-main-box">
      <div class="common-tab-box">
        <div class="common-tab-left">
          <div
            :class="tabIndex == index ? 'common-tab-list common-tab-active' : 'common-tab-list'"
            v-for="(item, index) in tabList"
            :key="index"
            @click="tabClick(item, index)"
          >
            {{ item.tabName }}({{ index == 0 ? dealTotalRows : messageTotalRows }})
          </div>
        </div>
        <div class="common-tab-right" @click="readAll()" v-if="tabIndex == 1 && messageTotalRows !== 0">全部已读</div>
      </div>
      <el-table :height="height" :data="tableData" stripe style="width: 100%;" v-loading="tableLoading">
        <el-table-column label="序号" width="60" header-align="center" align="center">
          <template slot-scope="scope">
            <!-- {{ scope.$index + 1 }} -->
            {{ (page.pageIndex - 1) * page.pageSize + scope.$index + 1 }}
          </template>
        </el-table-column>
        <el-table-column
          prop="title"
          label="通知标题"
          show-overflow-tooltip
          header-align="center"
          align="left"
          width="180"
        >
          <template slot-scope="scope"><span class="notice-title" @click="sellApprovalSubmitFormMsg(scope.row)">{{ scope.row.title }}</span></template>
        </el-table-column>

        <el-table-column prop="content" label="通知内容" show-overflow-tooltip header-align="center" align="left">
          <template slot-scope="scope">{{ scope.row.content }}</template>
        </el-table-column>

        <el-table-column
          prop="createTime"
          label="通知时间"
          show-overflow-tooltip
          header-align="center"
          align="left"
          width="160"
        >
          <template slot-scope="scope">{{ scope.row.createTime | getTime }}</template>
        </el-table-column>
        <el-table-column
          prop="isFinish"
          label="状态"
          show-overflow-tooltip
          header-align="center"
          align="left"
          width="70"
        >
          <template slot-scope="scope">
            <span class="msg-block msg-block-Y" v-if="scope.row.isFinish == 'Y'">{{
              scope.row.messageType == 1 ? '已处理' : '已读'
            }}</span>
            <span class="msg-block msg-block-N" v-if="scope.row.isFinish == 'N'">{{
              scope.row.messageType == 1 ? '未处理' : '未读'
            }}</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination
      class="pt pb back-color-fff"
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :page-sizes="[10, 50, 100, 200]"
      :current-page="page.pageIndex"
      :page-size="page.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="page.totalRows"
    >
    </el-pagination>
  </div>
</template>

<script>
import { route } from '@/api/apiRoute';
import store from '@/store';
import Title from '@/components/title/title.vue';
export default {
  components: {
    Title,
  },
  data() {
    return {
      height: window.innerHeight - 236,
      fullscreenLoading: false,
      dialogVisible: false,
      // detailVisible: false,
      page: {
        pageIndex: 1,
        pageSize: 50,
        totalPage: 0,
        totalRows: 0,
        messageType: 1,
        tenantGuid: null,
        staffGuid: null,
        isFinish: 'N',
      },
      tabIndex: 0,
      tabList: [{ tabName: '待办' }, { tabName: '消息' }],
      tableLoading: false,
      tableData: [],
      dealTotalRows: 0,
      messageTotalRows: 0,
      isFinishDealOption: [
        { label: '已处理', value: 'Y' },
        { label: '未处理', value: 'N' },
      ],
      isFinishMsgOption: [
        { label: '已读', value: 'Y' },
        { label: '未读', value: 'N' },
      ],
    };
  },
  created() {
    this.getList(1);
  },
  watch: {},
  methods: {
    tabClick(item, index) {
      this.tabIndex = index;
      this.page.messageType = index == 0 ? 1 : 2;
      this.getList(1);
    },
    readAll() {
      this.api
        .postJson(
          `/${serverConfig.msConfigureService}/message/data/update-state-by-staff-guid?staffGuid=${this.$route.query.staffGuid}`,
        )
        .then(res => {
          if (res.data.code == serverConfig.REQUIRE_SUCCESS_CODE) {
            this.$message.success('已全部已读！');
            this.getList(1);
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    getList(type) {
      if (type == 1) {
        this.page.pageIndex = 1;
      }
      this.page.tenantGuid = this.$route.query.tenantGuid;
      this.page.staffGuid = this.$route.query.staffGuid;
      this.api.postJson(`/${serverConfig.msConfigureService}/message/data/get-message-list`, this.page).then(res => {
        if (res.data.code == serverConfig.REQUIRE_SUCCESS_CODE) {
          this.tableData = res.data.data.records || [];
          this.page.totalPage = res.data.data.totalPages;
          this.page.totalRows = res.data.data.totalRows;
          if (this.tabIndex == 0) {
            this.dealTotalRows = res.data.data.totalRows;
          } else {
            this.messageTotalRows = res.data.data.totalRows;
          }
          this.getTotalList();
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    //获取总条数
    getTotalList() {
      let query = {
        messageType: this.tabIndex == 0 ? 2 : 1,
        pageIndex: 1,
        pageSize: 10,
        tenantGuid: this.$route.query.tenantGuid,
        staffGuid: this.$route.query.staffGuid,
        isFinish:this.page.isFinish
      };
      this.api.postJson(`/${serverConfig.msConfigureService}/message/data/get-message-list`, query).then(res => {
        if (res.data.code == serverConfig.REQUIRE_SUCCESS_CODE) {
          if (this.tabIndex !== 0) {
            this.dealTotalRows = res.data.data.totalRows;
          } else {
            this.messageTotalRows = res.data.data.totalRows;
          }
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    emptySearch() {
      this.page = {
        pageIndex: 1,
        pageSize: 50,
        totalPage: 0,
        totalRows: 0,
        messageType: this.page.messageType,
        tenantGuid: null,
        staffGuid: null,
        isFinish: null,
      };
      this.getList();
    },

    handleSizeChange(val) {
      let self = this;
      self.page.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      let self = this;
      self.page.pageIndex = val;
      this.getList();
    },
    sellApprovalSubmitFormMsg(row) {
      let self = this;
      let url;
      let tenantGuid = this.$route.query.tenantGuid;
      var productGuid = row.productGuid
      var tenantProductGuid = row.tenantProductGuid
      let path = row.jumpUrl;
      var frontEnv = window.env;//前端环境
      var systemEnv = localStorage.systemEnv;//后台环境
      if (frontEnv === 'pro'&&systemEnv=='pro') {
        //生产1
        url = `https://scm.csyaoly.com/LoginAccess?tenantGuid=${tenantGuid}&username=${self.$route.query.username}&password=${self.$route.query.password}&path=${encodeURIComponent(path)}&logoTitle=${self.$route.query.logoTitle}&productGuid=${productGuid}&tenantProductGuid=${tenantProductGuid}`;
      }else if (frontEnv === 'pro'&&systemEnv=='pre') {
        //生产2
        url = `https://scm.cs4pl.com/LoginAccess?tenantGuid=${tenantGuid}&username=${self.$route.query.username}&password=${self.$route.query.password}&path=${encodeURIComponent(path)}&logoTitle=${self.$route.query.logoTitle}&productGuid=${productGuid}&tenantProductGuid=${tenantProductGuid}`
      } else if (frontEnv === 'sit'&&systemEnv=='sit') {
        //测试1
        url = `https://scm-test.cs4pl.com/LoginAccess?tenantGuid=${tenantGuid}&username=${self.$route.query.username}&password=${self.$route.query.password}&path=${encodeURIComponent(path)}&logoTitle=${self.$route.query.logoTitle}&productGuid=${productGuid}&tenantProductGuid=${tenantProductGuid}`
      } else if (frontEnv === 'sit'&&systemEnv=='uat') {
        //测试2
        url = `https://scm-pre-test.cs4pl.com/LoginAccess?tenantGuid=${tenantGuid}&username=${self.$route.query.username}&password=${self.$route.query.password}&path=${encodeURIComponent(path)}&logoTitle=${self.$route.query.logoTitle}&productGuid=${productGuid}&tenantProductGuid=${tenantProductGuid}`
      } else {
        //开发
        url = `http://localhost:8084/LoginAccess?tenantGuid=${tenantGuid}&username=${self.$route.query.username}&password=${self.$route.query.password}&path=${encodeURIComponent(path)}&logoTitle=${self.$route.query.logoTitle}&productGuid=${row.productGuid}&tenantProductGuid=${tenantProductGuid}`
      }
      //window.location = url;
      window.open(url)
    },
  },
};
</script>
<style scoped>
.contract {
  margin: 0;
  background: #f2f2f2;
}
.search-area {
  margin-top: 1px;
  margin-bottom: 4px;
}
.el-col-8 {
  display: flex;
  align-items: center;
}
.msg-block-Y {
  display: inline-block;
  width: 44px;
  height: 22px;
  line-height: 22px;
  color: #ff991c;
  font-size: 12px;
  background: #FFFBF2;
  border: 1px solid rgba(255, 241, 212, 1);
  border-radius: 2px;
  text-align: center;
  box-sizing: border-box;
}
.msg-block-N {
  display: inline-block;
  width: 44px;
  height: 22px;
  line-height: 22px;
  font-size: 12px;
  background: #f5f5f5;
  border: 1px solid rgba(217, 217, 217, 1);
  border-radius: 2px;
  text-align: center;
  box-sizing: border-box;
}
/* 页面tab */
.common-tab-box {
  display: flex;
  justify-content: space-between;
  height: 32px;
  line-height: 32px;
  color: #666;
  margin: 16px 0 4px 0;
  border-bottom: 1px solid #e5e5e5;
  outline: none;
  background: #fff;
}
.common-tab-left {
  display: flex;
}
.common-tab-list {
  padding: 0 20px;
  /* margin: 0 5px; */
  text-align: center;
  box-sizing: border-box;
  cursor: pointer;
}
.common-tab-active {
  color: #4fa1a4;
  border-bottom: 2px solid #4fa1a4;
}
.common-tab-right {
  width: 64px;
  height: 28px;
  line-height: 28px;
  background: #ffffff;
  border: 1px solid rgba(217, 217, 217, 1);
  border-radius: 2px;
  font-size: 12px;
  color: #666666;
  cursor: pointer;
  margin-right: 5px;
}
.el-table /deep/ .el-input__inner {
  height: 40px;
  line-height: 40px;
}
/deep/ .el-checkbox__label {
  display: none;
}
.attach-sty {
  width: 170px;
  float: left;
  margin-top: 15px;
  text-align: center;
}
.checkbox-img-sty {
  border: 1px solid #dcdfe6;
  width: 16px;
  height: 16px;
  border-radius: 2px;
  cursor: pointer;
}
/deep/ .el-step__icon.is-text {
  border-radius: 50%;
  border-color: #4fa1a4;
  border-color: inherit;
}
/deep/ .el-step__icon-inner {
  color: #4fa1a4;
}
/deep/ .el-step__title.is-process {
  color: #4fa1a4;
}
.content-main-box {
  padding: 0 16px;
}
.notice-title{
  color: #4fa1a4;
  cursor: pointer;
}

</style>
