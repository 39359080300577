<template>
  <div class="title-box">
    <!-- <div class="page-back"  @click="goBack">
        <i class="el-icon-arrow-left" style="padding-right: 10px"></i> 
        返回</div> -->
    <div class="page-title">{{currentPageTitle}}</div>
  </div>
</template>
<script>
export default {
  props:{
    currentPageTitle:{
      type: String,
      default: '',
    }
  },
  data(){
    return{
      
    }
  },
  methods: {
    goBack(){
      this.$router.go(-1);
    }
  },
}
</script>
<style scoped>
.title-box {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  border-top: 1px solid #E5E5E5;
}
.page-back {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 68px;
  height: 28px;
  font-size: 12px;
  line-height: 28px;
  color: #212121;
  background: #ffffff;
  border-radius: 2px;
  cursor: pointer;
  margin-left: 16px;
  box-sizing: border-box;
  border: 1px solid #d9d9d9;
}
.page-title {
  flex: 1;
  font-size: 16px;
  text-align: center;
}
</style>
